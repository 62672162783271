<template>
  <div
    class="flex h-full items-center justify-between px-8 py-4 sm:flex-row md:flex-col md:py-8"
  >
    <div class="flex items-center gap-10 sm:flex-row md:flex-col">
      <img
        src="../../assets/logo_small.svg"
        class="my-2 max-h-10 dark:invert"
        alt="Logo"
        @click="goToHome()"
      />

      <component :is="routesComponent" />

      <router-link
        v-if="
          (currentRoute === routesNames.HOME ||
            currentRoute === routesNames.BUSINESS_ENTITIES_LIST) &&
          isDevelopmentEnvironment
        "
        data-testid="listTab"
        :to="{ name: 'BusinessEntitiesList' }"
      >
        <em
          :class="[
            'mdi mdi-list-box text-3xl hover:text-primary-2',
            {
              'text-primary-2':
                currentRoute === routesNames.BUSINESS_ENTITIES_LIST,
            },
          ]"
        ></em>
      </router-link>
    </div>

    <div class="flex items-center gap-5 sm:flex-row md:flex-col">
      <em
        v-if="
          isNative &&
          currentRoute !== routesNames.HOME &&
          currentRoute !== routesNames.BUSINESS_ENTITIES_LIST &&
          !isInspectionOrder
        "
        class="mdi mdi-remote text-3xl"
        data-testid="sideNav_openQTool"
        :class="[{ 'text-green-500': isConnected }]"
        @click="launchQToolProcess"
      ></em>

      <em
        v-if="isDevelopmentEnvironment"
        :class="devIcon"
        class="mdi text-3xl transition-all"
        @dblclick="dblClick"
      ></em>

      <OptionsDropdown direction="up" data-testid="sideNav_optionsDropdown">
        <template #toggle="{ onClick }">
          <div
            class="flex h-10 w-10 items-center justify-center rounded-xl bg-light-0 text-white shadow dark:bg-dark-0 hover:dark:bg-dark-4"
            @click="onClick"
          >
            <div
              class="no-selection text-xl font-bold text-black dark:text-white"
            >
              <em v-if="specialUserIcon" :class="specialUserIcon"></em>
              <div v-else>{{ firstLetter }}</div>
            </div>
          </div>
        </template>
        <li class="flex flex-row" @click="toggleDarkMode">
          <div class="mr-3 flex h-5 w-4 flex-col overflow-hidden">
            <div
              class="transition-all duration-500"
              :class="{ 'rotate-180': useLayoutStore().isDarkMode() }"
            >
              <em
                class="mdi mdi-weather-night flex items-center justify-center leading-5"
              />
              <em
                class="mdi mdi-white-balance-sunny mt-5 flex items-center justify-center leading-5"
              />
            </div>
          </div>
          {{ useLayoutStore().isDarkMode() ? 'Light Mode' : 'Dark Mode' }}
        </li>
        <li v-if="showAppDownloadDialog" @click="openAppDownloadDialog">
          <em class="mdi mdi-cellphone-arrow-down-variant mr-3 leading-6" />
          Download App
        </li>
        <li v-if="isNative" data-testid="openTutorial" @click="openTutorial">
          <em class="mdi mdi-school mr-3 leading-6" />
          Tutorial
        </li>
        <li data-testid="button-logout" @click="togglePrompt">
          <em class="mdi mdi-logout-variant mr-3 leading-6" />
          Logout
        </li>
        <li v-if="isDevelopmentEnvironment" @click="goToUpdaterPage">
          <em class="mdi mdi-logout-variant mr-3 leading-6" />
          Updater
        </li>
        <div
          data-testid="version"
          class="px-5 pb-2 text-light-disabled-text dark:text-dark-disabled-text"
        >
          Version {{ appVersion }}
        </div>
      </OptionsDropdown>
    </div>
  </div>
  <BasePrompt
    :open="promptOpen"
    :close="togglePrompt"
    :cancel="togglePrompt"
    :proceed="logout"
    title="Logout"
    question="Sind Sie sicher, dass Sie sich ausloggen möchten?"
  />
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/user/userStore';
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { default as envConfig } from '@/config/runtimeConfig';
import { useAppStore } from '@/store/mobile/AppStore';
import { Capacitor } from '@capacitor/core';
import { Order } from '@/models/Order';
import { routesNames } from '@/router';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { OnboardingDialog } from '@/native/plugins/OnboardingDialog';
import { FilterListItem, WebEvents } from '@/native/plugins/WebEvents';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useOrderStore } from '@/store/order/orderStore';

const router = useRouter();

const props = defineProps<{
  selectedOrder?: Order;
  isServiceListOpen?: boolean;
}>();
const emit = defineEmits(['toggleServiceList']);

const firstLetter = computed(() => {
  const fullName = useUserStore().getFullname;
  return fullName ? fullName.charAt(0) : undefined;
});

const specialUserIcon = computed(() => {
  if (useUserStore().userAttributes?.picture) {
    return `mdi ${useUserStore().userAttributes?.picture}`;
  }
  return undefined;
});

const isConnected = ref(false);

const isInspectionOrder = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const appVersion = computed(() => {
  return APP_VERSION;
});

const openAppDownloadDialog = () => {
  useAppStore().toggleAppDownloadDialog(true);
};

onMounted(() => {
  WebEvents.addListener('onQToolConnectivityChange', (data) => {
    isConnected.value = data.isConnected;
  });
});

const launchQToolProcess = () => {
  if (useEntitiesStore().activeBusinessEntity?.id === undefined) {
    return;
  }

  const filterList: Array<FilterListItem> = useFunkcheckStore().getFilterList;
  WebEvents.onStartQToolProcess({ filterList: filterList });
};

const openTutorial = () => {
  return OnboardingDialog.openDialog();
};

const showAppDownloadDialog = computed(() => {
  return (
    !Capacitor.isNativePlatform() && currentRoute.value === routesNames.HOME
  );
});

const isNative = computed(() => {
  return Capacitor.isNativePlatform();
});

const isDevelopmentEnvironment = computed(() => {
  return envConfig.isDevelopment === 'true';
});

const devIcon = ref('mdi-robot-outline text-primary-2');
const dblClicked = ref(0);
const dblClick = () => {
  dblClicked.value++;
  if (dblClicked.value === 3) {
    devIcon.value = 'mdi-robot-angry-outline animate-wiggle text-red-500';
    dblClicked.value = 0;
    setTimeout(() => {
      devIcon.value = 'mdi-robot-outline text-primary-2';
    }, 3000);
    return;
  }
  devIcon.value =
    'mdi-robot-happy-outline animate-[bounce_0.5s_ease-out_infinite] text-green-500';
  setTimeout(() => {
    devIcon.value = 'mdi-robot-outline text-primary-2 ';
  }, 2750);
};

const logout = () => {
  useUserStore()
    .logout()
    .then(() => {
      router.push({ name: 'Login' });
    });
};
const toggleDarkMode = () => {
  const layoutStore = useLayoutStore();
  if (layoutStore.isDarkMode()) {
    layoutStore.deactivateDarkMode();
  } else {
    layoutStore.activateDarkMode();
  }
};

const currentRoute = computed(() => {
  return router.currentRoute.value.name;
});

const goToUpdaterPage = () => {
  router.push({ path: '/updater' });
};

const goToHome = () => {
  router.push({ path: '/' });
};

const promptOpen = ref(false);
const togglePrompt = () => {
  promptOpen.value = !promptOpen.value;
};

const inspectionRoutes = defineAsyncComponent(
  () => import('@/components/SideMenu/Navigation/InspectionNav.vue')
);
const installationRoutes = defineAsyncComponent(
  () => import('@/components/SideMenu/Navigation/InstallationNav.vue')
);

const routesComponent = computed(() => {
  if (useOrderStore().isOnSiteInspection()) {
    return inspectionRoutes;
  }
  return installationRoutes;
});
</script>
