<template>
  <Loader />
  <router-view />
  <Notification></Notification>
</template>

<script>
import TheNotifications from '@/components/UI/Notification/TheNotifications';
import BaseLoader from '@/components/UI/Loader/BaseLoader';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { default as envConfig } from '@/config/runtimeConfig';
import { Capacitor } from '@capacitor/core';
import posthog from 'posthog-js';
import { installRelease } from '@/utils/liveUpdater';
import { fetchLatestReleases } from '@/api/mobile/AppInfoApi';

export default {
  name: 'App',
  components: {
    Notification: TheNotifications,
    Loader: BaseLoader,
  },
  data() {
    return {
      entitiesStore: useEntitiesStore(),
      layoutStore: useLayoutStore(),
      notificationStore: useNotificationStore(),
      onLine: navigator.onLine,
    };
  },
  computed: {
    businessEntities() {
      return this.entitiesStore.businessEntities;
    },
  },
  watch: {
    onLine(v) {
      this.layoutStore.setOnlineState(v);
    },
  },
  async mounted() {
    if (Capacitor.isNativePlatform()) {
      await LiveUpdate.ready();
    }

    if (posthog.isFeatureEnabled('is-live-update-enabled')) {
      const payload = posthog.getFeatureFlagPayload('is-live-update-enabled');

      if (payload['environments'].includes(envConfig.sentryEnv)) {
        fetchLatestReleases().then((data) => {
          if (data.length > 0) {
            const latestVersion = data[0];
            console.debug('Latest version' + latestVersion);
            installRelease(latestVersion).then(() => () => {
              console.debug('Live Updater Done');
            });
          }
        });
      }
    }
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
    const layoutStore = useLayoutStore();

    if (localStorage.getItem('isDarkMode') === 'true') {
      layoutStore.activateDarkMode();
      return;
    }

    if (localStorage.getItem('isDarkMode') === 'false') {
      layoutStore.deactivateDarkMode();
      return;
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      layoutStore.activateDarkMode();
    }
  },
  methods: {
    handleOnline() {
      this.onLine = true;
    },
    handleOffline() {
      this.onLine = false;
    },
  },
};
</script>
